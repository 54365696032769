import React from "react"
import Layout from "../components/layout"
import PageHeader from "./PageHeader"
import { AllWallpapers } from "../graphql/WallpaperPageFullQuery"
import Card from "../components/Card"
import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "react-i18next"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import CardModal from "../components/CardModal"
import { HaveSaleWallpapers } from "../graphql/SmallQueries"

const SaleTemplate = () => {
  const haveSale = HaveSaleWallpapers()

  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  const mn = currentLanguage === "mn"

  // console.log(HaveSaleWallpapers())

  const {
    saleMenu: { nodes },
  } = useStaticQuery(query)
  const menupath = nodes ? nodes[0].data : undefined
  const nameMn = menupath?.nameMn ?? undefined
  const nameEn = menupath?.nameEn ?? undefined
  const name = mn ? nameMn : nameEn
  // console.log(menupath)

  const textMn = menupath?.textMn ?? undefined
  const textEn = menupath?.textEn ?? undefined
  const text = mn ? textMn : textEn
  
  const textSale = haveSale ? text : undefined

  const sharppath = menupath?.Image ?? undefined
  const sharp = sharppath ? getImage(sharppath.localFiles[0]) : undefined
  const image = sharp ? (
    <GatsbyImage image={sharp} alt={name} className="w-full" />
  ) : undefined
  // console.log(sharppath)

  const saleWallpapers = AllWallpapers()
    .filter(wallpaper => wallpaper.sale)
    .sort(function (a, b) {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })

  const walls = saleWallpapers.map((item, index) => {
    return <CardModal key={index} data={item} />
  })

  

  return (
    <Layout>
      <PageHeader title={name} image={image} text={textSale} tall />
      <div>
        {!haveSale && (
          <div className="flex items-center justify-center h-full px-10 font-bold text-center text-gray-400 bg-gray-100 min-h-sm">
            Уучлаарай, одоогоор хямдрал зарлаагүй байна.
          </div>
        )}
        {haveSale && (
          <div className="grid max-w-screen-lg grid-cols-2 gap-6 px-4 pt-8 pb-32 mx-auto md:grid-cols-4 sm:grid-cols-3">
            {walls}
          </div>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    saleMenu: allAirtable(
      filter: { id: { eq: "bacf0eba-5be0-5884-962c-8b293fbf469d" } }
    ) {
      nodes {
        data {
          nameMn: Name_mongol
          nameEn: Name_english
          textMn: Mongol_tailbar
          textEn: English_tailbar
          Image: Menu_Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(width: 1000, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`

export default SaleTemplate
